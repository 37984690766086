@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--foreground-rgb: 0, 0, 0;
	--background-start-rgb: 214, 219, 220;
	--background-end-rgb: 255, 255, 255;
	--text-color-rgb: 255, 255, 255; /* RGB for white */
}

@media (prefers-color-scheme: dark) {
	:root {
		--foreground-rgb: 255, 255, 255;
		--background-start-rgb: 0, 0, 0;
		--background-end-rgb: 0, 0, 0;
	}
}

/* body {
	color: rgb(var(--foreground-rgb));
	background: linear-gradient(
			to bottom,
			transparent,
			rgb(var(--background-end-rgb))
		)
		rgb(var(--background-start-rgb));
} */

@layer utilities {
	.text-balance {
		text-wrap: balance;
	}
}

/* ripped from https://uk.tmconst.com/production-10-84-0-8248821/nucleus/font/Averta-*.woff2 */
@font-face {
	font-family: Averta;
	src: url(/fonts/Averta-Regular.woff2) format("woff2"),
		url(/fonts/Averta-Regular.woff) format("woff");
}

@font-face {
	font-family: Averta;
	src: url(/fonts/Averta-SemiBold.woff2) format("woff2"),
		url(/fonts/Averta-SemiBold.woff) format("woff");
	font-weight: 700;
}

@font-face {
	font-family: Averta;
	src: url(/fonts/Averta-ExtraBold.woff2) format("woff2"),
		url(/fonts/Averta-ExtraBold.woff) format("woff");
	font-weight: 800;
}

@font-face {
	font-family: Averta;
	src: url(/fonts/Averta-RegularItalic.woff2) format("woff2"),
		url(/fonts/Averta-RegularItalic.woff) format("woff");
	font-style: italic;
}

.custom-scrollbar {
	@apply scrollbar-thin scrollbar-thumb-neutral-700 scrollbar-track-transparent;
}

@layer base {
	:root {
		--background: 0 0% 100%;
		--foreground: 0 0% 3.9%;
		--card: 0 0% 100%;
		--card-foreground: 0 0% 3.9%;
		--popover: 0 0% 100%;
		--popover-foreground: 0 0% 3.9%;
		--primary: 0 0% 9%;
		--primary-foreground: 0 0% 98%;
		--secondary: 0 0% 96.1%;
		--secondary-foreground: 0 0% 9%;
		--muted: 0 0% 96.1%;
		--muted-foreground: 0 0% 45.1%;
		--accent: 0 0% 96.1%;
		--accent-foreground: 0 0% 9%;
		--destructive: 349.7 89.2% 60.2%;
		--destructive-foreground: 0 0% 98%;
		--border: 0 0% 89.8%;
		--input: 0 0% 89.8%;
		--ring: 0 0% 3.9%;
		--chart-1: 12 76% 61%;
		--chart-2: 173 58% 39%;
		--chart-3: 197 37% 24%;
		--chart-4: 43 74% 66%;
		--chart-5: 27 87% 67%;
		--radius: 0.5rem;
	}
	.dark {
		--background: 0 0% 3.9%;
		--foreground: 0 0% 98%;
		--card: 0 0% 3.9%;
		--card-foreground: 0 0% 98%;
		--popover: 0 0% 3.9%;
		--popover-foreground: 0 0% 98%;
		--primary: 0 0% 98%;
		--primary-foreground: 0 0% 9%;
		--secondary: 0 0% 14.9%;
		--secondary-foreground: 0 0% 98%;
		--muted: 0 0% 14.9%;
		--muted-foreground: 0 0% 63.9%;
		--accent: 0 0% 14.9%;
		--accent-foreground: 0 0% 98%;
		--destructive: 349.7 89.2% 60.2%;
		--destructive-foreground: 0 0% 98%;
		--border: 0 0% 14.9%;
		--input: 0 0% 14.9%;
		--ring: 0 0% 83.1%;
		--chart-1: 220 70% 50%;
		--chart-2: 160 60% 45%;
		--chart-3: 30 80% 55%;
		--chart-4: 280 65% 60%;
		--chart-5: 340 75% 55%;
	}
}

@layer base {
	* {
		@apply border-border;
	}
	body {
		@apply bg-background text-foreground;
	}
}
